import styled from "styled-components";
import { colors } from "../../styles";

interface IModalInterface {
  open?: boolean;
}

export const ModalContainer = styled.div`
  margin: 0 auto;
  align-self: center;
  max-width: 70em;
  width: 90%;
  z-index: 10;
  display: flex;
`;

export const ModalBody = styled.div`
  width: 70em;
  max-height: 700px;
  margin-top: 12px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: 0px 10px 10px -11px rgba(0, 0, 0, 0.32);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.gray20};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: ${colors.gray40};
  }
`;

export const ModalOverlay = styled.div<IModalInterface>`
  z-index: 1; /* Sit on top */
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.4);  
  display: ${(props: any) => (props.open ? "flex" : "none")};
`;

/* HEADER */
export const ModalHeader = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  padding: 22px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${colors.white};
  box-shadow: 0px 10px 10px -11px rgba(0, 0, 0, 0.32);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-left: 8px;
  min-height: 100%;
  line-height: 18px;
  font-style: normal;
  align-items: center;
  color: ${colors.primary};
  text-transform: uppercase;
`;

export const CloseButton = styled.button<
  React.HTMLAttributes<HTMLButtonElement>
>`
  padding: 0;
  border: none;
  font-size: 12px;
  cursor: pointer;
  background: none;
  outline: inherit;
  font-weight: 600;
  line-height: 5px;
  font-style: normal;
  letter-spacing: 4%;
  color: ${colors.gray80};
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.gray60};
    line-height: 19px;
`;

export const RightModalContainer = styled.div`
  margin-left: 20px;
  min-width: 250px;
  background-color: white;
  border-radius: 8px;
  height: auto;
  overflow: auto;
`
export const CenterModalComponent = styled.div`
  
`
