import styled, { keyframes } from "styled-components";
import { colors } from "./../../styles/index";


export const LoadingContainer = styled.div`

position: relative;
top: 40%;
left: 47.75%;
transform: translate(-50%, -50%);

border: 10px solid #f3f3f3;
border-top: 10px solid #3498db;
border-radius: 50%;
width: 70px;
height: 70px;
animation: spin 2s linear infinite;

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
`;

const pulse = keyframes`
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  `;

export const CustomerLogo = styled.img`
  width: 100%;
  height: 100%;
  animation: ${pulse} 1.5s infinite;
`;
