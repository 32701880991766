/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
} from "react";

//   import { getProfessional } from "services/Professional";
//   import LoadingOverlay from "components/LoadingOverlay";
import ProfessionalFilter from "../../components/ProfessionalFilter";
import ScheduleType from "../../components/ScheduleType";
import Specialty from "../../components/Specialty";
import CalendarPicker from "../../components/CalendarPicker";
import ScheduleGrid from "../../components/ScheduleGrid";
import { getProfessional, getServices } from "../../services/Professional";
import { getAgendamentosTasy, getLivresTasy } from "../../services/Agendamentos";
import { SideMenu, CalendarScreen, Container, DivLoading } from "./styles";
import LoadingOverlay from '../../components/LoadingOverlay/index'


interface ILoadings {
  mounting: boolean;
}

const initialLoadingState = {
  mounting: true,
};

const InitialScreen: React.FC = ({ children }) => {
  const [loadings, setLoadings] = useState<ILoadings>(initialLoadingState);
  const [professionals, setProfessionals] = useState<Array<{ id: number, value: string, label: string, selected: boolean }>>([]);
  const [selectedProfessionalIdList, setSelectedProfessionalIdList] = useState<Array<string>>([]);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState<number>();
  const [selectedTypes, setSelectedTypes] = useState<Array<string>>(new Array<string>());
  const [selectedSpecialty, setSelectedSpecialty] = useState<number>(0);
  const [specialties, setSpecialties] = useState<Array<{id: number, label: string}>>(new Array<{id: number, label: string}>());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [filteredDate, setFilteredDate] = useState<Date>(new Date());
  const [schedules, setSchedules] = useState([]);
  const [availables, setAvailables] = useState([]);
  
  const [linesTime, setLinesTime] = useState<Array<{time: string, listScheduled: Array<{id: string, date: Date, time:string, professional: string, paciente: string, type: number, status: string, convenio: string}>, listAvailable: Array<{date: Date, time: string, professional: string, type: number, professionalCpf: string, scheduleCode: string, professionalId: number}>}>>(new Array<{time: string, listScheduled: Array<{id: string, date: Date, time: string, professional: string, paciente: string, type: number, status: string, convenio: string}>, listAvailable: Array<{date: Date, time: string, professional: string, type: number, professionalCpf: string, scheduleCode: string, professionalId: number}>}>());
  
  const handleLoadings = (key: string, boolean: boolean) =>
    setLoadings((oldLoadings) => ({ ...oldLoadings, [key]: boolean }));

  const dataAgendamentos = async () => {
    let searchTerm = ``;

    if(!selectedProfessionalIdList || selectedProfessionalIdList.length === 0) return;

    if(selectedProfessionalIdList && selectedProfessionalIdList.length > 0) {
      searchTerm = `${searchTerm}&medicos=${selectedProfessionalIdList.toString()}`;
    }

    if(selectedSpecialty) {
      searchTerm = `${searchTerm}&especialidades=${selectedSpecialty}`;
    }
    
    if(filteredDate){
      searchTerm = `${searchTerm}&data=${filteredDate.toLocaleDateString('pt-BR', {day:'numeric'})}/${filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})}/${filteredDate.toLocaleDateString('pt-BR', { year:'numeric'})}`;
    }

    const response = await getAgendamentosTasy(searchTerm);

    setSchedules(response.data ? response.data : []);
  };

  const dataLivres = async () => {
    let searchTerm = ``;
    try{
      handleLoadings("mounting", true);
      
      if(!selectedProfessionalIdList || selectedProfessionalIdList.length === 0) return;
      if(selectedProfessionalIdList && selectedProfessionalIdList.length > 0) {
        searchTerm = `${searchTerm}&medicos=${selectedProfessionalIdList.toString()}`;
      }
  
      if(selectedSpecialty) {
        searchTerm = `${searchTerm}&especialidades=${selectedSpecialty}`;
      }
      
      if(filteredDate){
        searchTerm = `${searchTerm}&data=${filteredDate.toLocaleDateString('pt-BR', { year:'numeric'})}-${filteredDate.toLocaleDateString('pt-BR', { month: 'numeric'})}-${filteredDate.toLocaleDateString('pt-BR', {day:'numeric'})}`;
      }
  
      const response = await getLivresTasy(searchTerm);
  
      setAvailables(response.data ? response.data : []);
    }catch(error){
      console.error(error)
    }finally{
      handleLoadings("mounting", false);
    }
    
  };

  useEffect(() => {
    async function getInformation() {
      try {
        handleLoadings("mounting", true);
        
        const {
          data: { data },
        } = await getProfessional();

        setProfessionals(data.map((profissional : any) => {
          return {
            label: profissional.nome,
            selected: false,
            value: profissional.cpf,
            id: profissional.id
          }
        }))

        const servicos = await getServices();

        setSpecialties(servicos.data.data.map((specialty : any) => {
          return {
            id: specialty.id,
            label: specialty.nome,
          }
        }));

      } catch (error) {
        console.log(error);
        // alert("Ocorreu um erro ao retornar os pacientes \n" + error);
      } finally {
        handleLoadings("mounting", false);
      }
    }

    getInformation();
  }, []);

  useEffect(() => {
    dataAgendamentos();
    dataLivres();
  }, [selectedProfessionalIdList]);
  
  useEffect(() => {
    dataAgendamentos();
    dataLivres();
  }, [filteredDate]);

  useEffect(() => {
    let linesTimeH = Array.from({length: 24}, (_, i) => `${i}:00`);
    let linesTimeArr = linesTimeH.map((time: string) => {
      return {
        time,
        listAvailable: availables.filter((item: { NR_CPF_MED: string,
          CD_PESSOA_FISICA: string,
          CD_AGENDA: string,
          DS_AGENDA: string,
          DS_CLASSIF: string,
          DT_AGENDA: string,
          HR_AGENDA: string,
          IE_CLASSIF_AGENDA: string,
          NM_PESSOA_FISICA: string,
          QT_IDADE_MAX: number
          QT_IDADE_MIN: number}) => parseInt(item.HR_AGENDA) === parseInt(time))
          .map((item: { NR_CPF_MED: string,
            CD_PESSOA_FISICA: string,
            CD_AGENDA: string,
            DS_AGENDA: string,
            DS_CLASSIF: string,
            DT_AGENDA: string,
            HR_AGENDA: string,
            IE_CLASSIF_AGENDA: string,
            NM_PESSOA_FISICA: string,
            QT_IDADE_MAX: number,
            QT_IDADE_MIN: number,
            remoto: boolean,
            professionalCpf: string,
            scheduleCode: string,
          }) => {
          return { date: new Date(item.DT_AGENDA), time: item.HR_AGENDA, professional: item.DS_AGENDA, type: item.remoto ? 2 : 1, professionalCpf: item.NR_CPF_MED, scheduleCode: item.CD_AGENDA, professionalId: selectedProfessionalId ? selectedProfessionalId : 0}}),
        listScheduled: schedules.filter((item: { NR_CPF_MED: string,
          CD_PESSOA_FISICA: string,
          DS_AGENDA: string,
          DS_CLASSIF: string,
          DT_AGENDA: string,
          HR_AGENDA: string,
          IE_CLASSIF_AGENDA: string,
          NM_PESSOA_FISICA: string,
          QT_IDADE_MAX: number
          QT_IDADE_MIN: number}) => parseInt(item.HR_AGENDA) === parseInt(time)).map((item: { NR_CPF_MED: string,
            CD_PESSOA_FISICA: string,
            DS_AGENDA: string,
            DS_CLASSIF: string,
            DT_AGENDA: string,
            HR_AGENDA: string,
            IE_CLASSIF_AGENDA: string,
            NM_PESSOA_FISICA: string,
            NM_PACIENTE: string,
            DS_STATUS_AGENDA: string,
            DS_CONVENIO: string,
            QT_IDADE_MAX: number
            remoto: boolean,
            contato: boolean,
            status: string,
            QT_IDADE_MIN: number}) => {
          return { id: item.NR_CPF_MED, date: new Date(item.DT_AGENDA), time: item.HR_AGENDA, professional: item.DS_AGENDA, paciente: item.NM_PACIENTE, type: item.remoto ? 2 : 1, status: item.DS_STATUS_AGENDA, convenio: item.DS_CONVENIO}
        })
      }
    });

    setLinesTime(linesTimeArr);

  }, [schedules]);

  useEffect(() => {
    async function getInformation() {
      try {
        handleLoadings("mounting", true);

        let searchTerm;
        
        if (selectedTypes && selectedTypes.length > 0) {
          searchTerm = `tipoAgenda=${selectedTypes.toString()}`;
        }

        if (!searchTerm && selectedSpecialty) {
          searchTerm = `especialidade=${selectedSpecialty}`;
        }else if(searchTerm && selectedSpecialty){
          searchTerm = `${searchTerm}&especialidade=${selectedSpecialty}`;
        }

        const {
          data: { data },
        } = await getProfessional(searchTerm);

        setProfessionals(data.map((profissional : any) => {
          return {
            label: profissional.nome,
            selected: false,
            value: profissional.cpf,
            id: profissional.id
          }
        }))

      } catch (error) {
        console.log(error);
        // alert("Ocorreu um erro ao retornar os pacientes \n" + error);
      } finally {
        handleLoadings("mounting", false);
      }
    }

    getInformation();
  }, [selectedTypes, selectedSpecialty]);

  return (
    <Container>
      {loadings.mounting && <DivLoading><LoadingOverlay/></DivLoading>}
      <SideMenu>
        <CalendarPicker selectedDate={filteredDate} setSelectedDate={setFilteredDate}/>
        <ScheduleType selectedTypes={selectedTypes} setSelectedTypes={setSelectedTypes} />
        <Specialty selectedSpecialty={selectedSpecialty} setSelectedSpecialty={setSelectedSpecialty} specialties={specialties} />
        <ProfessionalFilter
          professionals={professionals}
          selectedIdList={selectedProfessionalIdList}
          setSelectedIdList={setSelectedProfessionalIdList}
          setSelectedProfessionalId={setSelectedProfessionalId}
        />
      </SideMenu>
      <CalendarScreen>
        <ScheduleGrid selectedDate={selectedDate} setSelectedDate={setSelectedDate} filteredDate={filteredDate} linesTime={linesTime} selectedProfessional={selectedProfessionalIdList.toString()} selectedSpecialty={selectedSpecialty}/>
      </CalendarScreen>
    </Container>
  );
};

export default InitialScreen;