import React, { useState } from 'react';

import SelectInput from '../SelectInput';

import { Container, Label, NoData } from './styles';

interface ILayout {
  selectedSpecialty? : number, 
  setSelectedSpecialty? : (selectedSpecialty : number) => void,
  specialties : Array< { id: number, label: string}>,
}

const Specialty: React.FC<ILayout> = (props) => {
  const { specialties, selectedSpecialty, setSelectedSpecialty} = props;
  const [selectedItem, setSelectedItem] = useState(null);

  const onChange = (event : any) => {
    if(!setSelectedSpecialty) return;

    setSelectedSpecialty(event.target.value);
  }

  const normalizeSelectSpecialities = () => {
    return specialties.map( (item: any) => {
      return {
        description: item.label,
        value: item.id,
        selected: item.id === selectedSpecialty,
        id: item.id,
      }
    });
  }

  return (
    <Container>
      <Label>Selecione uma Especialidade: </Label>
      {(specialties && specialties.length > 0) && (
      <SelectInput
        selectedOption={normalizeSelectSpecialities().find((especialidade) => especialidade.id == selectedSpecialty)}
        name="specialities"
        onSelect={ (val: any) => {
          !!setSelectedSpecialty && setSelectedSpecialty(val.id)
          !!setSelectedItem && setSelectedItem(val.description)
        }}
        options={normalizeSelectSpecialities()}
        placeholder={!!selectedItem ?  selectedItem : 'Selecione...'}
      />)
      }
      {
          (!specialties || specialties.length ===0) && (
            <NoData>Nenhuma especialidade encontrado</NoData>
          )
        }
    </Container>
  );
};

export default Specialty;

