import { colors } from "./../../styles/index";
import styled from "styled-components";

export const DivRow = styled.div `
  content: "";
  display: flex;
  clear: both;
  align-items: flex-end;
`;

export const DivCol = styled.div `
  display: grid;
  float: left;
  width: 50%;
  padding: 10px;
`;

export const DatePicker = styled.input `
  border: 0px;
  background-color: ${colors.gray15};
  height: 32px;
  margin-bottom: 10px;
  margin-left: 5px;
  padding-left: 4px;
    color: #838484;
    border-radius: 4px;
`;

export const Label = styled.label `
  color: ${colors.gray60};
  margin-left: 8px;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 10px;
`;

export const DivCard = styled.div `
  margin-top: 60px;
  margin: 43px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
`;

export const DivLoading = styled.div `
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  z-index: 5;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #646464;
`;

export const Tbody = styled.tbody`

`

export const Tr = styled.tr`

`;

export const Td = styled.td`
  padding: 14px 15px;
  border-bottom: 1px solid #DEDEDE;
  text-align: left;
`;

export const Hr = styled.hr`

`;

export const Button = styled.button`
    height: 32px;
    background-color: ${colors.primary};
    color: white;
    border-radius: 4px;
    margin-left: 5px;
    border: 0px;
    padding-inline: 15px;
    margin-inline: 6px;
    :hover{
      background-color: #094C8F;
    }
    `;
    
  export const ButtonCancel = styled.button`
    height: 32px;
    background-color: ${colors.red};
    color: white;
    border-radius: 4px;
    margin-left: 5px;
    border: 0px;
    padding-inline: 15px;
    margin-inline: 6px;
    :hover{
      background-color: #AB2020;
    }
`;

export const ErrorLabel = styled.span`
  font-size: 10px;
  color: ${colors.red};
`


export const Container = styled.div`
  text-align: -webkit-center;
`;

export const SearchBarSchedule = styled.input`
  width: 300px;
  max-width: 100%;
  padding-left: 5px;
  height: 32px;
  display: flex;
  max-width: 520px;
  border-radius: 4px;
  padding-right: 8px;
  align-items: center;
  background: #F5F5F5;
  `

export const ContainerTable = styled.div`
  margin: 53px;
  margin-top: 0;
`
export const NoData = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  margin-left: 5px;
  margin-top: 15px;
  color: ${colors.gray40};
`;