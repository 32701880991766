import { colors } from "../../styles/index";
import styled from "styled-components";

export const SideMenu = styled.div`
  margin: 10px;
`;
export const CalendarScreen = styled.div`
  padding-left: 25px
`;
export const Container = styled.div`
  display: grid;
  grid-template-columns: 260px auto;
`;

export const DivLoading = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  z-index: 5;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
`
